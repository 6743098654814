.scroller {
  --scroller-speed: 30s;
  --content-width: 100px;
  --content-height: 100px;
  animation: scroll var(--scroller-speed) linear infinite;
  will-change: transform;
}

.scroller.vertical {
  animation-name: scrollVertical;
}

.scroller.reverse {
  animation-direction: reverse;
}

.scroller.pauseOnHover:hover,
.scroller.pauseOnClick:active {
  animation-play-state: paused;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-1 * var(--content-width)));
  }
}

@keyframes scrollVertical {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(calc(-1 * var(--content-height)));
  }
}

